const XDate = function() {};

/** MySQL DATE_FORMAT() 函数 标准 
 * %a	缩写星期名
 * %b	缩写月名
 * %c	月，数值
 * %D	带有英文前缀的月中的天
 * %d	月的天，数值(00-31)
 * %e	月的天，数值(0-31)
 * %f	微秒
 * %H	小时 (00-23)
 * %h	小时 (01-12)
 * %I	小时 (01-12)
 * %i	分钟，数值(00-59)
 * %j	年的天 (001-366)
 * %k	小时 (0-23)
 * %l	小时 (1-12)
 * %M	月名
 * %m	月，数值(00-12)
 * %p	AM 或 PM
 * %r	时间，12-小时（hh:mm:ss AM 或 PM）
 * %S	秒(00-59)
 * %s	秒(00-59)
 * %T	时间, 24-小时 (hh:mm:ss)
 * %U	周 (00-53) 星期日是一周的第一天
 * %u	周 (00-53) 星期一是一周的第一天
 * %V	周 (01-53) 星期日是一周的第一天，与 %X 使用
 * %v	周 (01-53) 星期一是一周的第一天，与 %x 使用
 * %W	星期名
 * %w	周的天 （0=星期日, 6=星期六）
 * %X	年，其中的星期日是周的第一天，4 位，与 %V 使用
 * %x	年，其中的星期一是周的第一天，4 位，与 %v 使用
 * %Y	年，4 位
 * %y	年，2 位
 * 
 * 例如：DATE_FORMAT(NOW(),'%Y-%m-%d %H:%i:%s.%f'
 * 结果：
 */

/**
 * function formatTimestamp 日期格式化，格式为'Y-m-d H:i:s'或'Y-m-d'等，By Sieyoo赵向明
 * 和PHP一样的时间戳格式化函数
 * @param {string} format 格式
 * @param {int} timestamp 要格式化的时间 默认为当前时间
 * @return {string}   格式化的时间字符串
 */
XDate.formatTimestamp = function(format = 'Y-m-d H:i:s', timestamp) {
	var a, jsdate = ((timestamp) ? new Date(timestamp * 1000) : new Date());
	var pad = function(n, c) {
		if ((n = n + "").length < c) {
			return new Array(++c - n.length).join("0") + n;
		} else {
			return n;
		}
	};
	var txt_weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
	var txt_ordin = {
		1: "st",
		2: "nd",
		3: "rd",
		21: "st",
		22: "nd",
		23: "rd",
		31: "st"
	};
	var txt_months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September",
		"October", "November", "December"
	];
	var f = { // Day

		d: function() {
			return pad(f.j(), 2)
		},
		D: function() {
			return f.l().substr(0, 3)
		},
		j: function() {
			return jsdate.getDate()
		},
		l: function() {
			return txt_weekdays[f.w()]
		},
		N: function() {
			return f.w() + 1
		},
		S: function() {
			return txt_ordin[f.j()] ? txt_ordin[f.j()] : 'th'
		},
		w: function() {
			return jsdate.getDay()
		},
		z: function() {
			return (jsdate - new Date(jsdate.getFullYear() + "/1/1")) / 864e5 >> 0
		},
		// Week
		W: function() {
			var a = f.z(),
				b = 364 + f.L() - a;
			var nd2, nd = (new Date(jsdate.getFullYear() + "/1/1").getDay() || 7) - 1;
			if (b <= 2 && ((jsdate.getDay() || 7) - 1) <= 2 - b) {
				return 1;
			} else {
				if (a <= 2 && nd >= 4 && a >= (6 - nd)) {
					nd2 = new Date(jsdate.getFullYear() - 1 + "/12/31");
					return date("W", Math.round(nd2.getTime() / 1000));
				} else {
					return (1 + (nd <= 3 ? ((a + nd) / 7) : (a - (7 - nd)) / 7) >> 0);
				}
			}
		},
		// Month
		F: function() {
			return txt_months[f.n()]
		},
		m: function() {
			return pad(f.n(), 2)
		},
		M: function() {
			return f.F().substr(0, 3)
		},
		n: function() {
			return jsdate.getMonth() + 1
		},
		t: function() {
			var n;
			if ((n = jsdate.getMonth() + 1) == 2) {
				return 28 + f.L();
			} else {
				if (n & 1 && n < 8 || !(n & 1) && n > 7) {
					return 31;
				} else {
					return 30;
				}
			}
		},
		// Year
		L: function() {
			var y = f.Y();
			return (!(y & 3) && (y % 1e2 || !(y % 4e2))) ? 1 : 0
		},
		//o not supported yet
		Y: function() {
			return jsdate.getFullYear()
		},
		y: function() {
			return (jsdate.getFullYear() + "").slice(2)
		},
		// Time
		a: function() {
			return jsdate.getHours() > 11 ? "pm" : "am"
		},
		A: function() {
			return f.a().toUpperCase()
		},
		B: function() { // peter paul koch:

			var off = (jsdate.getTimezoneOffset() + 60) * 60;
			var theSeconds = (jsdate.getHours() * 3600) + (jsdate.getMinutes() * 60) + jsdate.getSeconds() + off;
			var beat = Math.floor(theSeconds / 86.4);
			if (beat > 1000) beat -= 1000;
			if (beat < 0) beat += 1000;
			if ((String(beat)).length == 1) beat = "00" + beat;
			if ((String(beat)).length == 2) beat = "0" + beat;
			return beat;
		},
		g: function() {
			return jsdate.getHours() % 12 || 12
		},
		G: function() {
			return jsdate.getHours()
		},
		h: function() {
			return pad(f.g(), 2)
		},
		H: function() {
			return pad(jsdate.getHours(), 2)
		},
		i: function() {
			return pad(jsdate.getMinutes(), 2)
		},
		s: function() {
			return pad(jsdate.getSeconds(), 2)
		},
		//u not supported yet
		// Timezone
		//e not supported yet
		//I not supported yet
		O: function() {
			var t = pad(Math.abs(jsdate.getTimezoneOffset() / 60 * 100), 4);
			if (jsdate.getTimezoneOffset() > 0) t = "-" + t;
			else t = "+" + t;
			return t;
		},
		P: function() {
			var O = f.O();
			return (O.substr(0, 3) + ":" + O.substr(3, 2))
		},
		//T not supported yet
		//Z not supported yet
		// Full Date/Time
		c: function() {
			return f.Y() + "-" + f.m() + "-" + f.d() + "T" + f.h() + ":" + f.i() + ":" + f.s() + f.P()
		},
		//r not supported yet
		U: function() {
			return Math.round(jsdate.getTime() / 1000)
		}
	};
	return format.replace(/[\\]?([a-zA-Z])/g, function(t, s) {
		let val = "";
		if (t != s) { // escaped
			val = s;
		} else if (f[s]) { // a date function exists
			val = f[s]();
		} else { // nothing special
			val = s;
		}
		return val;
	});
};



// 对Date的扩展，将 Date 转化为指定格式的String   
// 月(M)、日(d)、小时(H)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，   
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)   
// 例子：   
// XDate.format("yyyy-mm-dd HH:ii:ss.f") ==> 2006-07-02 08:09:04.423   
XDate.format = function(format = "yyyy-mm-dd HH:ii:ss.fff", date) { //author: meizz
	if(date === undefined){
		date = new Date()
	}
	var o = {
		"m+": date.getMonth() + 1, //月份   
		"d+": date.getDate(), //日  
		"H+": date.getHours(), //小时   
		"i+": date.getMinutes(), //分   
		"s+": date.getSeconds(), //秒   
		"q+": Math.floor((date.getMonth() + 3) / 3), //季度
        // "y+": date.getDay(), //年
        // "w": date.getDay(), //周
        // "f+": date.getDay(), //毫秒
	};
    var txt_weekdays = ["日", "一", "二", "三", "四", "五", "六"];
	if (/(y+)/.test(format))
		format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
	if (/(f+)/.test(format))
		format = format.replace(RegExp.$1, ("0000" + (RegExp.$1.length === 1 ? Math.round(date.getMilliseconds()/100) : RegExp.$1.length === 2 ? Math.round(date.getMilliseconds()/10) : date.getMilliseconds())).substr(- RegExp.$1.length));
    if (/(w+)/.test(format))
        format = format.replace(RegExp.$1, txt_weekdays[date.getDay()]);
	for (var k in o)
		if (new RegExp("(" + k + ")").test(format))
			format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
	return format;
}



// 转为时间戳
XDate.toTimeStamp = function(dateStr = '2015-03-05 17:59:00') {
	dateStr = dateStr.substring(0, 19);
	dateStr = dateStr.replace(/-/g, '/');
	let timestamp = new Date(dateStr).getTime()/1000;
	return timestamp;
}

// 感觉没啥用
XDate.toDate = function(dateStr = '2015-03-05 17:59:00') {
	dateStr = dateStr.substring(0, 19);
	dateStr = dateStr.replace(/-/g, '/');
	let timestamp = new Date(dateStr).getTime();

	// 根据毫秒数构建 Date 对象
	let date = new Date(timestamp);
	// 格式化日期
	let dateTime = date.toLocaleString();
	return dateTime;
}



export default XDate
